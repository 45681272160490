<template>
  <!-- <common-page-loader v-if="loading" /> -->
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <div class="mb-4 text-h4 font-weight-medium">
        Common Share Returns
      </div>
      <common-trs-data-table
        :headers="headers"
        :items="commonShareDetails"
        :loading="loading"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <template v-slot:[`item.financing_rounds`]="{item }">
          <span>
            {{ item.investors }}
          </span>
        </template>
        <template v-slot:[`item.shares_participating`]="{item }">
          <span>
            {{ item.shares_participating }}
          </span>
        </template>
        <template v-slot:[`item.strike_acq_price`]="{item }">
          <span>
            {{ item.strike_acq_price !== null ? $currencyWithPrecision(item.strike_acq_price) : 'NA' }}
          </span>
        </template>
        <template v-slot:[`item.net_payout`]="{item }">
          <span>
            {{ $currencyWithPrecision(item.net_payout) || 'NA' }}
          </span>
        </template>
        <template v-slot:[`item.ownership`]="{item }">
          <span>
            {{ (item.ownership*100).toFixed(2) }}%
          </span>
        </template>
        <template v-slot:[`item.total_return_dollar`]="{item }">
          <span>
            {{ $currencyWithPrecision(item.total_return_dollar) || 'NA' }}
          </span>
        </template>
        <template v-slot:[`item.total_return_multiplier`]="{item }">
          <span>
            {{ item.total_return_multiplier !== null ? `${item.total_return_multiplier.toFixed(2)}x` : 'NA' }}
          </span>
        </template>
        <template v-slot:no-data>
          <span> No Results Found. </span>
        </template>
      </common-trs-data-table>
    </template>
  </common-trs-page-container>
</template>
<script>

  export default {
    name: 'CommonShareReturns',

    components: {
    },

    props: {
      commonShareDetails: {
        type: Array,
        required: true,
      },

    },

    data: () => ({
      loading: false,
      totalCount: 0,
      headers: [
        { text: 'Financing Rounds', value: 'financing_rounds' },
        { text: 'Shares Participating', value: 'shares_participating' },
        { text: 'Strike / Acquisition Price', value: 'strike_acq_price' },
        { text: 'Common Shares Payout', value: 'net_payout' },
        { text: 'Ownership', value: 'ownership' },
        { text: 'Total Proceeds Received', value: 'total_return_dollar' },
        { text: 'Mul', value: 'total_return_multiplier' },
      ],
      isCommon: true,
      filters: {},
      portal: '',
    }),
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-data-table__wrapper table thead tr {
    th {
        background: var(--v-trsBack-base);
    }
  }
  ::v-deep .v-data-table__wrapper table tr {
    &:last-child {
      td {
        background: var(--v-accent-base);
      }
    }
  }
</style>
