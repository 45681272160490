<template>
  <div class="summary-view-wrapper px-6 py-3 ma-3">
    <v-row class="mt-0">
      <v-col
        cols="12"
        md="6"
        lg="3"
      >
        <div class="font-weight-medium">
          {{ scenarioName }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          Created on: {{ $date(waterfallDetails.exits_on) }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
      >
        <div class="font-weight-medium">
          {{ $currencyWithPrecision(waterfallDetails.exit_amount) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          Exit Value
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
      >
        <div class="font-weight-medium">
          {{ $currencyWithPrecision(waterfallDetails.price_per_share) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          Common Share Price
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
      >
        <div class="font-weight-medium">
          {{ $date(waterfallDetails.exits_on) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          Exit Date
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>

  export default {
    name: 'WaterfallSummaryView',

    components: {
    },

    props: {
      scenarioName: {
        type: String,
        required: true,
      },
      waterfallDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    computed: {
    },
  }
</script>
<style lang="scss" scoped>
  .summary-view-wrapper {
    background: var(--v-trsBack-base);
    border-radius: 4px;
  }
</style>
