var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-trs-page-container',{attrs:{"padding":0},scopedSlots:_vm._u([{key:"page-content",fn:function(){return [_c('div',{staticClass:"mb-4 text-h4 font-weight-medium"},[_vm._v(" Common Share Returns ")]),_c('common-trs-data-table',{attrs:{"headers":_vm.headers,"items":_vm.commonShareDetails,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.financing_rounds",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.investors)+" ")])]}},{key:"item.shares_participating",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.shares_participating)+" ")])]}},{key:"item.strike_acq_price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.strike_acq_price !== null ? _vm.$currencyWithPrecision(item.strike_acq_price) : 'NA')+" ")])]}},{key:"item.net_payout",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(item.net_payout) || 'NA')+" ")])]}},{key:"item.ownership",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s((item.ownership*100).toFixed(2))+"% ")])]}},{key:"item.total_return_dollar",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(item.total_return_dollar) || 'NA')+" ")])]}},{key:"item.total_return_multiplier",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.total_return_multiplier !== null ? ((item.total_return_multiplier.toFixed(2)) + "x") : 'NA')+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No Results Found. ")])]},proxy:true}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }