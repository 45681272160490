var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-trs-page-container',{attrs:{"padding":0},scopedSlots:_vm._u([{key:"page-content",fn:function(){return [_c('div',{staticClass:"mb-4 text-h4 font-weight-medium"},[_vm._v(" Preferred Share Returns ")]),_c('common-trs-data-table',{attrs:{"headers":_vm.headers,"items":_vm.preferredShareDetails,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.financing_rounds",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.investors)+" ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.priority === 1 ? ((item.priority) + "st"): item.priority === 2 ? ((item.priority) + "nd") : item.priority === 3 ? ((item.priority) + "rd") : item.priority > 3 ? ((item.priority) + "th") : 'NA')+" ")])]}},{key:"item.net_payout",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(item.net_payout))+" ")])]}},{key:"item.ownership",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s((item.ownership*100).toFixed(2))+"% ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No Results Found. ")])]},proxy:true}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }