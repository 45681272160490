<template>
  <!-- <common-page-loader v-if="loading" /> -->
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <div class="mb-4 text-h4 font-weight-medium">
        Preferred Share Returns
      </div>
      <common-trs-data-table
        :headers="headers"
        :items="preferredShareDetails"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <template v-slot:[`item.financing_rounds`]="{item }">
          <span>
            {{ item.investors }}
          </span>
        </template>
        <template v-slot:[`item.priority`]="{item }">
          <span>
            {{ item.priority === 1 ? `${item.priority}st`: item.priority === 2 ? `${item.priority}nd` : item.priority === 3 ? `${item.priority}rd` : item.priority > 3 ? `${item.priority}th` : 'NA' }}
          </span>
        </template>
        <template v-slot:[`item.net_payout`]="{item }">
          <span>
            {{ $currencyWithPrecision(item.net_payout) }}
          </span>
        </template>
        <template v-slot:[`item.ownership`]="{item }">
          <span>
            {{ (item.ownership*100).toFixed(2) }}%
          </span>
        </template>
        <template v-slot:no-data>
          <span> No Results Found. </span>
        </template>
      </common-trs-data-table>
    </template>
  </common-trs-page-container>
</template>
<script>

  export default {
    name: 'PreferredShareReturns',

    components: {
    },

    props: {
      preferredShareDetails: {
        type: Array,
        required: true,
      },

    },

    data: () => ({
      loading: false,
      totalCount: 0,
      headers: [
        { text: 'Financing Rounds', value: 'financing_rounds' },
        { text: 'Payment Priority', value: 'priority' },
        { text: 'Liquidation Preference Payout', value: 'net_payout' },
        { text: 'Ownership', value: 'ownership' },
      ],
      isCommon: true,
      filters: {},
      portal: '',
    }),
  }
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__wrapper table thead tr {
    th {
        background: var(--v-trsBack-base);
    }
  }
  ::v-deep .v-data-table__wrapper table tr {
    &:last-child {
      td {
        background: var(--v-accent-base);
      }
    }
  }
</style>
