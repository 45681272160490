<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="`Waterfall Analysis -${scenarioName}`"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
         <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Waterfall Analysis - {{scenarioName}}</span>
      </v-card-title>
      <common-trs-card class="mx-6 mt-5">
        <waterfall-summary-view
          :scenario-name="scenarioName"
          :waterfall-details="waterfallDetails"
        />
        <div class="preferred-returns ma-3 pa-3">
          <preferred-share-returns
            :preferred-share-details="preferredShareDetails"
          />
        </div>
        <div class="preferred-returns ma-3 pa-3">
          <common-share-returns
            :common-share-details="commonShareDetails"
          />
        </div>
        <div
          v-if="totalReturns.length>0"
          class="ma-3 pb-5"
        >
          Total:
          <span class="font-weight-medium">
            {{ $currencyWithPrecision(totalReturns[0].net_payout) }}
          </span>
        </div>
        <!-- <div class="ma-3 text-right">
          <common-trs-btn
            type="secondary"
            medium
            text
            :to="{name: 'Scenarios'}"
          >
            Cancel
          </common-trs-btn>
        </div> -->
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import WaterfallSummaryView from '@/components/common/captable/components/WaterfallSummaryView'
  import PreferredShareReturns from './PreferredShareReturns'
  import CommonShareReturns from './CommonShareReturns'

  export default {
    name: 'ScenarioFinancingRound',

    components: {
      WaterfallSummaryView,
      PreferredShareReturns,
      CommonShareReturns,
    },

    data: () => ({
      loading: false,
      waterfallData: [],
      waterfallDetails: {},
      preferredShareDetails: [],
      commonShareDetails: [],
      totalReturns: [],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      scenarioId () {
        return this.$route.query.id
      },
      scenarioName () {
        return this.$route.query.name
      },
    },

    mounted () {
      this.loadWaterFall()
    },

    methods: {
      clearNullData (element) {
        Object.keys(element).forEach((key) => {
          if (element[key] === null || element[key] === undefined) {
            delete element[key]
          }
        })
      },

      async loadWaterFall () {
        try {
          const resp = await captableService.getWaterFallById(this.captableId, this.scenarioId)
          this.waterfallData = resp.data
          this.waterfallDetails = this.waterfallData.additional_information
          this.preferredShareDetails = this.waterfallData.preferred_returns
          this.commonShareDetails = this.waterfallData.common_returns
          this.totalReturns = this.waterfallData.total_returns
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      close () {
        this.$router.push({ name: 'Scenarios' })
      },
    },
  }
</script>
<style lang="scss">
.preferred-returns {
    border: 1px solid var(--v-trsBack-base);
    border-radius: 4px;
}

</style>
